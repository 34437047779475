var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(_setup.AccessGrid, {
        attrs: {
          preFilter: _vm.preFilter,
          "allow-remove": "true",
          viaAccess: _vm.viaAccess,
          currentType: _setup.currentType,
          refreshKey: _setup.props.refreshKey,
        },
        on: { "remove-relationship": _setup.onRemoveRelationship },
        scopedSlots: _vm._u(
          [
            _setup.allowAdd
              ? {
                  key: "add-relation",
                  fn: function () {
                    return [
                      _c(
                        "v-btn",
                        {
                          staticClass: "primary mt-4",
                          attrs: { dark: "" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              _setup.selectorVisible = true
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { dark: "", center: "" } }, [
                            _vm._v("mdi-playlist-plus"),
                          ]),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
      }),
      _c(_setup.RelationshipSelector, {
        attrs: {
          "domain-type": _vm.viaAccess.domain,
          "selection-type": _setup.currentType,
        },
        on: { "add-relationship": _setup.onAddRelationship },
        model: {
          value: _setup.selectorVisible,
          callback: function ($$v) {
            _setup.selectorVisible = $$v
          },
          expression: "selectorVisible",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }